<template>
  <router-link target="_blank" :to="path">
    <div class="list-content row s_start">
      <div>
        <div>
          <img alt="封面图" class="image-size" :src="coverImage" />
        </div>
        <!-- <p class="gray-unit" v-if='item.houseNo' style="margin-top:5px;">{{item.houseNo+'      关注'}}</p> -->
      </div>
      <div class="flex1">
        <p class="title">{{title}}</p>
        <ul class="row s_center ul-list">
          <li class="ul_item">{{item.propertyTypeDesc}}</li>
          <li class="ul_item" v-if="item.propertyTypeDesc">/</li>
          <li class="ul_item">{{(item.room||'--')+"室" + (item.hall||'--') + "厅"}}</li>
          <li class="ul_item">/</li>
          <li class="ul_item">{{item.buildingArea+'m²'}}</li>
          <li class="ul_item" v-if="item.buildingArea">/</li>
          <li class="ul_item">{{orientation}}</li>
          <!-- <li class="ul_item">{{item.propertyTypeDesc}}</li> -->
          <!-- <li class="ul_item" v-if="item.currentFloor" >/</li>
                    <li class="ul_item">{{item.currentFloor+"(共" + item.totalFloor + "层)"}}</li> -->
          <!-- <li class="ul_item" v-if="item.buildingArea" >/</li>
                    <li class="ul_item">{{item.buildingArea+'平米'}}</li> -->
          <li class="ul_item" v-if="orientation">/</li>
          <li class="ul_item">{{decorate}}</li>
        </ul>
        <ul class="row s_center wrap" style="margin-top:2px;">
          <li class="tag" v-for="(ele,index) in tag" :key="ele+index">{{ele}}</li>
        </ul>
        <ul class="price row s_end">
          <li class="red-price">
            {{(currentTab !=1?item.totalPrice:item.rentPrice)||''}}
          </li>
          <li class="red-price" v-if="currentTab !=1 && item.totalPrice" style="font-size:14px;margin-left:5px;">万</li>
          <li class="gray-unit" v-if='currentTab !=1 && item.averagePrice'>{{item.averagePrice+' 元/平'}}</li>
          <li class="red-price" v-if='currentTab ==1 && item.rentPrice' style="font-size:14px;margin-left:5px;">元/月</li>
        </ul>
      </div>
    </div>
    <div style="height:1px;background-color:#E6E6E6;"></div>
  </router-link>
</template>

<script>
import Constant from "./../../../common/Constant";
export default {
  name: "house",
  props: {
    item: Object,
    currentTab: Number
  },
  data() {
    return {};
  },
  computed: {
    path() {
      let result;
      if (this.currentTab === 1) {
        result = {
          path: "/rentHouseDetail/1",
          query: {
            guid: this.item.guid,
            tradeType: this.currentTab
          }
        };
      } else {
        result = {
          path: "/houseDetail/2",
          query: {
            guid: this.item.guid,
            tradeType: this.currentTab
          }
        };
      }
      return result;
    },
    coverImage() {
      return this.item.coverImage || Constant.DEFAULT_HOUSE;
    },
    title: function() {
      let str = "";
      const { title, room, hall, orientation } = this.item;
      if (title) {
        str += title + "   ";
      }
      if (room) {
        str += room + "室" + hall + "厅" + "   ";
      }
      if (orientation && orientation !== "未选择") {
        str += orientation;
      }
      return str;
    },
    orientation: function() {
      const { orientation } = this.item;
      return orientation && orientation != "未选择" ? orientation : "";
    },
    decorate: function() {
      const { decorate } = this.item;
      return decorate && decorate != "未选择" ? decorate : "";
    },
    tag: function() {
      const { tags } = this.item;
      if (Array.isArray(tags)) {
        return tags;
      }
      return [];
    }
  },
  watch: {},
  components: {},
  methods: {},
  created() {}
};
</script>

<style scoped lang="less">
@width: 30px;
@height: 80px;
.list-content {
  margin: 10px 0;

  .image-size {
    width: 105px;
    height: @height;
    margin-right: 15px;
    border-radius: 3px;
  }

  .title {
    font-size: 15px;
    color: #000000;
    // font-weight: 600;
  }

  .ul-list {
    color: #101d37;
    font-size: 14px;
    font-weight: 500;
    font-family: Hiragino Sans GB;
    margin: 5px 0;

    .ul_item {
      // margin-right: 25px;
    }
  }

  .tag {
    color: #849aae;
    font-size: 8px;
    background-color: #f5f5f5;
    padding: 7px 10px;
    margin: 2px 4px 2px -8px;
    -webkit-transform: scale(0.8);
  }

  .price {
    height: 30px;

    .red-price {
      color: #fe615a;
      font-size: 20px;
      font-weight: 600;
    }

    .gray-unit {
      color: #9399a5;
      font-size: 14px;
      font-weight: 300;
      margin-left: 10px;
    }
  }
}
</style>